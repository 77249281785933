import { graphql, Link, withPrefix } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Meta from 'components/meta'
import Layout from 'components/layout'

import headshot from './../../dist/img/headshot.png'
import cpcc from './../../dist/img/cpcc.png'
import icf from '../../dist/img/icf.png'
import pmp from '../../dist/img/pmp.png'

import { useJsonForm } from 'gatsby-tinacms-json'

let lang = 'en'

const EnAboutIndex = ({ location, data }) => {
  const [node] = useJsonForm(data.dataJson, AboutPageForm)
  const page = node.pages.about.en
  return (
    <Layout location={location}>
      <Helmet>
        <title>{page.site_title} - Reinhold Rößler</title>
      </Helmet>
      <div className="about-body" style={{ marginTop: `90px` }}>
        <header style={{ backgroundColor: `#35424b` }}>
          <div
            className="container mobile-only"
            style={{ display: `grid`, paddingTop: `120px` }}
          >
            <img
              src={headshot}
              style={{ width: `70%`, height: `auto`, paddingLeft: `10px` }}
            />
            <div
              style={{
                marginLeft: `30px`,
                color: `white`,
                paddingTop: `15%`,
                paddingBottom: `15%`,
              }}
            >
              <h5 style={{ fontSize: `1rem` }}>{page.bio.title}</h5>
              <h1 style={{ fontSize: `1.5rem`, fontFamily: `Pier sans` }}>
                {page.bio.name}
              </h1>
              <p>{page.bio.body}</p>
            </div>
            <div className="about-links about-links-mobile">
              <div className="container">
                <div className="row" style={{ paddingBottom: `1rem` }}>
                  <div className="col">
                    <img src={cpcc} />
                  </div>
                  <div className="col">
                    <img src={icf} />
                  </div>
                  <div className="col">
                    <img src={pmp} />
                  </div>
                </div>
                <div className="row" style={{ textAlign: `center` }}>
                  <div className="col">
                    <a
                      href={withPrefix('/cv_en.pdf')}
                      target="_blank"
                      style={{ fontSize: `1rem` }}
                    >
                      Check out my CV here
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row desktop-only"
            style={{
              display: `flex`,
              paddingBottom: `2rem`,
              paddingTop: `2rem`,
            }}
          >
            <div className="col-4" style={{ margin: `auto` }}>
              <img
                src={headshot}
                style={{ width: `70%`, display: `block`, margin: `auto` }}
              />
            </div>
            <div
              className="col-7"
              style={{ color: `white`, marginRight: `5rem` }}
            >
              <div className="about-text">
                <h5>{page.bio.title}</h5>
                <h1>{page.bio.name}</h1>
                <p>{page.bio.body}</p>
              </div>
              <div className="about-links">
                <div className="row">
                  <div className="col">
                    <a href={withPrefix('/cv_en.pdf')} target="_blank">
                      Check out my CV here
                    </a>
                  </div>
                  <div className="col">
                    <img src={cpcc} />
                  </div>
                  <div className="col">
                    <img src={icf} />
                  </div>
                  <div className="col">
                    <img src={pmp} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h2 style={{ color: `#35424B` }}>
                  {page.professional_experience.text}
                </h2>
                <ul style={{ textAlign: `left` }}>
                  {page.professional_experience.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
                <h2 style={{ color: `#35424b` }}>{page.education.text}</h2>
                <ul>
                  {page.education.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default EnAboutIndex

const AboutPageForm = {
  fields: [
    {
      name: `rawJson.pages.about.${lang}.site_title`,
      component: 'text',
      label: 'Site Title',
      required: true,
    },
    {
      label: 'Bio',
      name: `rawJson.pages.about.${lang}.bio`,
      description: 'Bio',
      component: 'group',
      fields: [
        {
          name: `title`,
          component: 'text',
          label: 'Title',
          required: true,
        },
        {
          name: `name`,
          component: 'text',
          label: 'Name',
          required: true,
        },
        {
          name: `body`,
          component: 'textarea',
          label: 'Description',
          required: true,
        },
      ],
    },
    {
      label: 'Professional Experience',
      name: `rawJson.pages.about.${lang}.professional_experience`,
      description: 'Professional Experience',
      component: 'group',
      fields: [
        {
          name: `text`,
          component: 'text',
          label: 'Professional Experience',
          required: true,
        },
        {
          component: 'group-list',
          name: 'points',
          label: 'Points',
          required: true,
          fields: [
            {
              label: 'Text',
              name: 'text',
              component: 'text',
            },
          ],
          itemProps: item => ({
            label: item.text ? item.text : 'Bullet Point',
            key: item,
          }),
        },
      ],
    },
    {
      label: 'Primary and Further Education',
      name: `rawJson.pages.about.${lang}.education`,
      description: 'Primary and Further Education',
      component: 'group',
      fields: [
        {
          name: `text`,
          component: 'text',
          label: 'Primary and Further Education',
          required: true,
        },
        {
          component: 'group-list',
          name: 'points',
          label: 'Points',
          required: true,
          fields: [
            {
              label: 'Text',
              name: 'text',
              component: 'text',
            },
          ],
          itemProps: item => ({
            label: item.text ? item.text : 'Bullet Point',
            key: item,
          }),
        },
      ],
    },
  ],
}

export const pageQuery = graphql`
  query {
    dataJson {
      pages {
        about {
          en {
            site_title
            bio {
              title
              name
              body
            }
            professional_experience {
              text
              points {
                text
              }
            }
            education {
              text
              points {
                text
              }
            }
          }
        }
      }
      rawJson
      fileRelativePath
    }
  }
`
